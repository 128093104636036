/*   Attach File   */
.attachButtonWrap {
  margin-bottom: 36px;
}

.attachButtonWrap .attachButton {
  padding-left: 5px;

  color: var(--palette-primary);

  font-weight: 500;
}

.attachButton .clipIcon path {
  fill: var(--palette-primary);
}

.attachButtonWrap .attachButton:hover {
  color: var(--palette-primary-hover);
}

.attachButton:hover .clipIcon path {
  fill: var(--palette-primary-hover);
}

.attachButtonWrap .attachButton:active {
  color: var(--palette-primary-active);
}

.attachButton:active .clipIcon path {
  fill: var(--palette-primary-active);
}

.attachButtonWrap .attachButton:focus {
  color: var(--palette-primary-focus);
}

.attachButton:focus .clipIcon path {
  fill: var(--palette-primary-focus);
}

.attachedFilesList {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  list-style-type: none;
}

.attachedFileOrder {
  margin-right: 4px;

  color: var(--palette-primary);
}

.attachedFileItem {
  display: flex;

  border-bottom: 1px solid var(--palette-primary-disabled);
}

.attachedFileItem:not(:first-child) {
  padding-top: 4px;
}

.attachButtonWrap .btnRemoveAttachedFile {
  width: 16px;
  height: 16px;
  margin-left: auto;
  padding: 0;
}

/* Attach Modal */
.portal .modalContent {
  width: 1280px;
  padding: 32px;
}

.attachFileModalControlsWrapper,
.attachFileModalTableWrap {
  margin-bottom: 24px;
}

.description {
  font-size: 13px;
  color: var(--palette-text-secondary);
  margin-top: 6px;
  margin-bottom: 24px;
}

.attachFileModalTitle {
  color: var(--palette-text-default);
  margin-bottom: 0;

  font-size: 22px;
  font-weight: 500;
}

.attachFileModalControlsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attachFileModalSearch {
  margin-right: 10px;
}

.attachFileModalSwitchWrapper {
  display: flex;
  align-items: center;
}

.attachFileModalSwitch {
  margin: 0 5px;
}

.attachFileModalTable {
  max-height: calc(100vh - 360px);
}

.attachFileModalTable :global(.headerCell:first-of-type) {
  margin-right: 0;
}

.attachFileModalTableWrap {
  --global-scroll-width: 4px;

  padding: 4px;

  border-radius: 8px;
  background: var(--palette-tertiary-transparent);
}

.attachFileModalActionsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}
