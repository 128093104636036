.sidebar {
  border-radius: 10px 5px 5px 10px;
  background: var(--palette-default);
}

/* Buttons */

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 30px 0;
}

/* Button */

.roleBtn {
  display: block;
  overflow: hidden;

  width: 100%;
  padding: 10px 24px;

  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;

  color: var(--palette-text-secondary);
  border: 0;
  background: transparent;

  font-size: 12px;
  font-weight: normal;
  font-style: normal;
}

.roleBtn:hover,
.roleBtn:focus {
  color: var(--palette-text-default);
  background: var(--palette-default-focus);
}

.roleBtn:disabled {
  cursor: default;
}

.roleBtn.activeBtn {
  color: var(--palette-text-default);
  background: var(--palette-default-hover);
}

/* Add */

.addBtn {
  width: 100%;

  text-align: left;
}

.addingBtn {
  color: var(--palette-text-default);
  background: var(--palette-default-hover) !important;
}
