.allocationRangeWrap {
  width: 100%;
  padding: 12px 6px 6px;

  color: var(--palette-text-default);
  border-radius: 16px;
  background-color: var(--palette-body);
}

.headerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 6px;
}

.headerTextsWrap {
  display: flex;
  align-items: center;

  margin: 0 6px;

  color: var(--grey-body);
}

.headerTextsWrap span {
  padding: 0 10px;
}

.headerTextsWrap span:first-child {
  padding-left: 0;
}

.headerTextsWrap span:not(:last-child) {
  border-right: 1px solid var(--palette-text-secondary);
}

.allocationRangeWrap .detailsButton {
  height: initial;
  margin-right: 24px;
  padding: 0;
}

.distributionWrap {
  overflow: hidden;

  border-radius: 10px;
  background: var(--palette-default);
}
