.targetUrlField :global(.CodeMirror) {
  height: 120px;
}

.targetUrlLabel {
  label {
    span {
      &::before {
        height: 40%;
      }
    }
  }
}

.fileTabs :global(.treeFileTab__navWrapper) {
  padding-top: 0;
  padding-left: 0;
}

.urlPreviewWrapper {
  font-size: 13px;
  line-height: 1.5;
}

.urlPreviewTitle {
  color: var(--palette-text-primary);

  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
}

.urlKey {
  color: var(--palette-text-primary);
}

.urlValue {
  color: var(--palette-primary-active);
}

.urlValueParam {
  color: var(--palette-warning);
}

.urlNestedKey {
  margin-left: 12px;

  color: var(--palette-text-primary);
}

:global(.messageField).filesErrorMessage {
  height: auto;
  margin-top: -24px;
  margin-bottom: 12px;
}

.filesErrorMessage svg {
  align-self: flex-start;
  flex-shrink: 0;
}

.filesErrorMessage :global(.messageField__text) {
  -webkit-line-clamp: 10;

  white-space: break-spaces;
}

.headersLabel label span::before {
  height: calc(50% - 1px);
}
