.container {
  background-color: #f4f4f5;
  border-radius: 6px;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
}

.button {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.75rem;
  color: #71717a;
  background-color: #f4f4f5;
  text-decoration: none;
  border: none;
  transition: 0.2s ease;
  cursor: pointer;
  width: 100%;
  min-width: 100px;
}

.button:disabled {
  opacity: 0.5;
  cursor: auto;
}

.active {
  color: #09090b;
  background-color: #fff;
  border-radius: 6px;
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
