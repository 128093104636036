.fileNameWrap {
  max-width: 200px;
}

.disabled {
  pointer-events: none;
}

.dateCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.checkboxCell.override:first-of-type {
  margin-right: 0;
}

.headersCell {
  min-width: 150px;
  max-width: 150px;
}

.loader {
  width: 15px;
  height: 15px;
}

.badge {
  min-width: 120px;
}

.text {
  flex-shrink: 0;
}

.statusCell {
  pointer-events: auto !important;
}
