.actionsWrap {
  display: flex;
  gap: 10px;

  flex-wrap: wrap;

  margin-top: 32px;
}

button.backToGeneralButton {
  align-self: flex-start;

  margin-bottom: 16px;
  padding: 4px 0;

  color: var(--palette-grey-active);

  font-size: 13px;
  column-gap: 4px;
}

.backToGeneralButton svg {
  transform: scaleX(-1);
}
