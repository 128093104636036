.slideControllers {
  display: flex;
}

.slideControllers .slideController {
  width: 25px;
  height: 4px;
  margin-right: 15px;

  cursor: pointer;

  border-radius: 3px;
  background: var(--palette-grey-hover);
}

.slideControllers .slideController:last-child {
  margin-right: 0;
}

.slideControllers .slideControllerActive {
  background: var(--palette-default);
}
