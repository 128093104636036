.tooltipTrigger {
  margin-left: 6px;
}

.tooltipContent {
  width: 147px;
  height: 50px;

  margin-top: 18px;
  padding: 8px;

  border-radius: 8px;
}
