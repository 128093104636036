.sidebarWrapper.override {
  width: auto !important;
}

.closeSidebarBtn {
  position: absolute;
  top: 10px;

  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px;

  cursor: pointer;

  border: none;

  background-color: transparent;
  appearance: none;
}

.closeSidebarBtn::-moz-focus-inner {
  border: 0;
}

.closeIcon {
  display: block;

  width: 13px;
  height: 13px;
}

.backdrop {
  display: none;
}

.logoutBtnBox {
  position: relative;

  display: none;

  margin-top: auto;
  padding: 19px 0 0;
}

.logoutBtnBox:before {
  position: absolute;
  top: 0;
  right: -17px;
  left: -17px;

  display: block;

  height: 1px;

  content: "";

  background-color: var(--palette-tertiary-disabled);
}

.logoutBtn {
  cursor: pointer;

  color: var(--palette-grey-active);

  border: none;
  outline: none;
  background-color: transparent;
  appearance: none;
}

.logoutBtn::-moz-focus-inner {
  border: 0;
}

/* toggler */

.toggler {
  position: absolute;
  top: 78px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  padding: 4px;

  cursor: pointer;
  transition: transform ease-in-out 0.4s;
  transform: rotate(0);

  border: none;
  border-radius: 50%;
  background: var(--sidebar-toggler-background);
}

.toggler.left {
  right: -9px;

  transform: rotate(180deg);
}

.toggler.collapsed {
  transform: rotate(0);
}

.togglerImage {
  width: 100%;
}

/* Logo */

.logo {
  display: grid;
  overflow: hidden;
  align-items: center;

  margin-bottom: 42px;

  cursor: pointer;

  color: var(--palette-primary);

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.logo img {
  width: 36px;
  height: 36px;
}

.logo:hover {
  opacity: 0.8;
}

/* Menu */

.menu {
  display: grid;
  gap: 22px;

  overflow: hidden;

  padding-left: 8px;
}

/* Link */

.link {
  position: relative;

  display: grid;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  white-space: nowrap;
  text-decoration: none;

  color: var(--palette-text-secondary);

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  grid-template-columns: 20px 1fr;
  gap: 8px;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}

.link img {
  filter: grayscale(100%) brightness(150%);
}

.link.collapsed .linkText {
  visibility: hidden;

  width: 0;
}

/* Link active */

.activeLink {
  position: relative;

  color: var(--palette-text-default);
}

.activeLink img {
  filter: none;

  opacity: 1;
}

@media (max-width: 992px) {
  .sidebarWrapper.override {
    position: fixed;
    z-index: 6;
  }

  .sidebarWrapper {
    top: 0;
    bottom: 0;
    left: 0;
  }

  .sidebarWrapper.collapsed {
    transform: translateX(calc(var(--sidebar-width) * -1));
  }

  .backdrop {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    visibility: visible;

    transition-duration: 0.4s;

    transition-property: opacity, visibility;

    opacity: 1;

    background-color: rgba(0, 21, 97, 0.18);
  }

  .backdrop.collapsed {
    visibility: hidden;

    opacity: 0;
  }

  .link {
    font-size: 13px;
  }
}

@media (max-width: 450px) {
  .logoutBtnBox {
    display: block;
  }
}
