.title {
  font-size: 22px;
  font-weight: 500;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions button:first-of-type {
  margin-right: 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 100%;
  }
}
