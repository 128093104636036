.calendar {
  --borderStyle: 1px solid var(--palette-primary-disabled);
  max-width: 826px;
}

.weekdays,
.dayGrid {
  display: grid;

  margin: 0 auto;
  padding: 0;
  grid-template-columns: repeat(7, 1fr);
}

.dayGrid {
  border-top: var(--borderStyle);
  border-left: var(--borderStyle);
}

.weekdayTitle {
  text-decoration: none;
  text-transform: uppercase;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
}

.weekday {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0;

  list-style: none;
}

.weekdays {
  margin-bottom: 2px;
}

.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 14px;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.legendIcon {
  width: 16px;
  height: 16px;
}
