.allocationContainer {
  padding-top: 5px;
}

.containerWrap {
  border-radius: 8px;
}

.formContentWrap {
  display: flex;
  flex-direction: column;
}

.allocationNavBtnWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.allocationNavBtnText {
  margin: 0;

  color: var(--palette-grey-active);

  font-size: 11px;
  line-height: 1.5;
}

.allocationNavBtnWrapper {
  margin-top: 0;
}

.allocationNavBtn {
  flex: 1;
}

.formContentWrap .allocationBtns {
  margin-top: 8px;
}

.confirmBackToGeneralPopup > div:first-child {
  max-width: 398px;
}
