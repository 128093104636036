.tabs {
  //border: 1px solid #e1e1e1;
  //max-width: 500px;
}

.header {
  display: flex;
  gap: 20px;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
}

.search {
  margin-bottom: 0;
}

.content {
  margin-top: 24px;
}

.formHeader {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 24px;

  h1 {
    margin: 0 !important;
  }

  h2 {
    margin: 0 !important;
  }

  p {
    margin: 0;
    color: var(--palette-text-secondary);
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
