.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
}

.crumb {
  display: flex;
  align-items: center;
}

.separator {
  width: 56px;
}

.crumb .crumbButton {
  padding: 5px 16px;

  color: var(--palette-grey-disabled);
  border-radius: 4px;
}

.crumb .crumbButton:hover,
.crumb .crumbButton:active {
  color: var(--grey-body);
}

.crumb .crumbButton:focus-within {
  color: var(--grey-body);
  outline: 1px solid var(--grey-body);
}

.crumb .crumbButton.crumbButtonActive {
  color: var(--grey-body);
  background-color: var(--palette-grey-lightly);
}

.breadcrumbsList {
  display: flex;

  align-items: center;

  margin: 0;
  padding: 0;

  list-style: none;
}

.icon {
  margin-left: 8px;
}
