/* User */
.user {
  display: flex;
  align-items: center;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 0 5px 0 0;

  border: 1px solid var(--palette-tertiary-transparent);
  border-radius: 10px;
}

.userName {
  max-width: 160px;
}

/* Date */
.dateCell {
  grid-column-gap: 8px;

  column-gap: 8px;

  align-items: center;

  white-space: nowrap;
}

.dateCell img {
  margin: 0 0 0 6px;
}
