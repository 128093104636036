.infoWrapper {
  padding: 48px 36px;

  border-radius: 8px;

  background: var(--palette-default);
}

.info {
  margin-bottom: 16px;
  padding: 15px;

  border-left: 5px solid var(--palette-warning);
  border-radius: 8px;
  background: var(--palette-warning-hover);

  font-size: 13px;

  font-weight: 400;
  line-height: 17px;
}

.error {
  border-left: 5px solid var(--palette-error);
  background: var(--palette-error-hover);
}

.title,
.messageTitle {
  margin-bottom: 5px;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--grey-body);

  font-size: 12px;

  font-weight: 600;
  line-height: 18px;
}

.messageTitle {
  margin-bottom: 8px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;

  margin-top: 40px;
}

.table {
  overflow-x: auto;

  width: 100%;
  margin-bottom: 30px;
  padding: 12px 7px 7px;

  color: var(--palette-text-default);
  border-radius: 16px;
  background-color: var(--palette-grey-table-bg);
}

.text {
  padding: 0 12px;

  color: var(--grey-body);
}

.row {
  margin-top: 4px;
  padding: 10px 12px;

  color: var(--grey-body);

  border-radius: 10px;
  background: var(--palette-default);

  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

.customInfo {
  margin-top: -20px;
  margin-bottom: 20px;
}

.customInfo.error {
  margin-top: 0;
}
