/* Reuse Job */
.reuseButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}

.reuseButtonsWrapper .reuseButton {
  width: 100%;
  max-width: 280px;

  margin-bottom: 0;

  font-weight: 500;
}

.reuseButtonsWrapper .clearReuseJobBtn {
  justify-content: flex-start;

  padding: 5px;

  font-size: 12px;
}

/* Reuse Job Modal */
.portal .modalContent {
  padding: 32px;
}

.reuseJobModalTitle {
  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
}

.reuseJobModalSearch,
.reuseJobModalTitle,
.reuseJobModalTableWrap {
  margin-bottom: 24px;
}

.reuseJobModalTableWrap {
  --global-scroll-width: 4px;
  padding: 4px;

  border-radius: 8px;
  background: var(--palette-tertiary-transparent);
}

.reuseJobModalTable {
  max-height: 400px;
}

.reuseJobModalActionsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

@media (min-width: 992px) {
  .reuseButtonsWrapper {
    margin-bottom: 24px !important;
  }
}
