.headersLabel label span::before {
  height: calc(50% - 1px);
}

.configSelect {
  height: 32px !important;
}

.configGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}
