.description {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.mainLabel {
  font-size: 13px;
  font-weight: 700;
  color: var(--label-color);
  margin-bottom: 0;
}
