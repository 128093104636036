/*  Scroll Styling ↓ */
.addScrollStyles::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.addScrollStyles::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background-color: var(--global-scroll-background-color);
}

.addScrollStyles::-webkit-scrollbar-corner {
  background-color: transparent;
}

.addScrollStyles::-webkit-scrollbar-thumb:hover {
  background-color: var(--global-scroll-background-hover-color);
}

.addScrollStyles::-webkit-scrollbar-thumb:active {
  background-color: var(--global-scroll-background-active-color);
}

.hideScrollBar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.applyOverflow {
  overflow: hidden;
}

/*  Text-Overflowed Styling  */
.applySingleOverflow {
  display: inline-block !important;
  overflow: hidden !important;

  min-width: initial;
  max-width: 100%;

  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  word-break: keep-all !important;
}

.applyMultiOverflow {
  display: -webkit-box !important;
  overflow: hidden !important;

  min-width: initial !important;

  word-break: break-word !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

/* Misc Styling */
/* make element invisible but keep it's sizes, position and remove ability to interact with it */
.hideElement {
  visibility: hidden !important;

  cursor: initial !important;
  pointer-events: none !important;

  opacity: 0 !important;
}

/* make element invisible but leave the possibility of interaction */
.maskElement {
  position: absolute !important;

  opacity: 0 !important;
}

/* make element disappear */
.deleteElement {
  display: none !important;
}

/* For Firefox */
.numberFieldAsTextField {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
.numberFieldAsTextField::-webkit-inner-spin-button,
.numberFieldAsTextField::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
