.week,
.daysList,
.day {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.week {
  margin-top: 8px;
}

.week:first-child {
  margin-top: 0;
}

.week .daysList {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  max-height: 0;

  cursor: pointer;
  gap: 8px;
}

.daysList.collapsed {
  max-height: none;
}

.day,
.weekTitle {
  margin: 0;

  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.weekTitle {
  cursor: pointer;
}

.selectedDay,
.weekSelected .weekTitle,
.selectedDay .usedValue {
  color: var(--palette-primary);
}

.dayWeekName,
.usedValue {
  color: var(--palette-primary-disabled);
}

.dayWeekName {
  margin-right: 5px;
}
