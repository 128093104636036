.jobChartWrap {
  position: relative;

  min-height: 260px;
  padding: 15px;

  border-radius: 16px;
  background: var(--palette-default);
}

.jobChartSelectsWrap {
  display: flex;
  justify-content: space-between;

  margin: 0 20px 20px;
}

.jobChartSelectsWrap .jobChartSelect > [class*="container"],
.jobChartSelectsWrap .jobChartSelect [class*="inputWrapper"] {
  flex-grow: 1;
}

.jobChartSelectsWrap .jobChartSelect input {
  height: 100%;
}

.jobChartSelectsWrap .jobChartSelect:first-child {
  max-width: 200px;
}

.jobChartSelectsWrap .jobChartSelect:nth-child(2) {
  max-width: 220px;
}

.jobChartSelect {
  width: 40%;
  min-width: 200px;
}

/*  ChartMain   */
.chartWrap {
  position: relative;

  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}

.chartWrap * {
  user-select: none;

  font-family: Lato, serif;
}

.chartWrap svg,
.chartWrap svg * {
  overflow: initial;
}

:global(.recharts-yAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-xAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-cartesian-grid-horizontal line) {
  stroke: var(--palette-tertiary-transparent);

  opacity: 0.4;
}

:global(.recharts-layer.recharts-cartesian-axis line) {
  stroke: var(--palette-tertiary-transparent);
}

:global(.recharts-layer.recharts-line.realTraffic path) {
  stroke: var(--palette-primary);
}

:global(.recharts-layer.recharts-line.suppressedTraffic path) {
  stroke: var(--palette-warning);
}

/*  ChartMain Tooltip   */
.tooltipWrap {
  width: 174px;
  padding: 8px;

  border-radius: 10px;
  background: var(--palette-default);
  box-shadow: 0 0 6px var(--palette-shadow-small);
}

.tooltipDate {
  margin-bottom: 5px;

  color: var(--palette-text-default);

  font-weight: 600;
}

.tooltipItem {
  display: flex;
  align-items: center;

  padding: 3px 2px;
}

.tooltipCaptionWrap {
  display: flex;
  align-items: baseline;

  min-width: 100px;
  max-width: 100px;
  margin-right: 10px;
}

.tooltipVisualSign {
  width: 12px;
  height: 12px;
  margin-right: 6px;

  border-radius: 2px;
}

.tooltipDivider {
  margin: 8px 0;

  border: 1px solid var(--palette-primary-disabled);
}

.tooltipFrozenInfo {
  padding: 4px;

  border-radius: 4px;

  background-color: var(--palette-primary-transparent);
}

:global(path.recharts-rectangle) {
  transition: 0.2s;
}

:global(path.succeededTraffic) {
  fill: var(--palette-success-hover);
}

:global(path.succeededTraffic:hover) {
  fill: var(--palette-success-active);
}

:global(path.failedTraffic) {
  fill: var(--palette-error-hover);
}

:global(path.failedTraffic:hover) {
  fill: var(--palette-error-focus);
}

:global(path.targetTraffic) {
  fill: var(--palette-primary-transparent);
}

:global(path.targetTraffic:hover) {
  fill: var(--palette-secondary-focus);
}

:global(path.suppressedButSentTraffic) {
  fill: var(--palette-warning-hover);
}

:global(path.suppressedButSentTraffic:hover) {
  fill: var(--palette-warning-focus);
}

.tooltipVisualSign.succeededTraffic {
  background-color: var(--palette-success-hover);
}

.tooltipVisualSign.failedTraffic {
  background-color: var(--palette-error-hover);
}

.tooltipVisualSign.targetTraffic {
  background-color: var(--palette-primary-transparent);
}

.tooltipVisualSign.suppressedButSentTraffic {
  background-color: var(--palette-warning-hover);
}

.todayLine line {
  fill: var(--palette-primary);
  stroke: var(--palette-primary) !important;
}

/*  ChartMain Preview   */
.chartPreviewWrap {
  margin: 0 30px 40px 50px;
}

.chartPreviewRangeTrack {
  height: 3px;

  border-radius: 2px;
  background: var(--palette-tertiary-transparent);
}

.chartPreviewRangeThumb {
  position: relative;
  z-index: 2;

  width: 11px;
  height: 11px;

  border-radius: 50%;
  background: var(--range-controller-background-primary);
  box-shadow: var(--range-controller-shadow);
}

.chartPreviewRangeThumb:not(.firstThumb) .chartPreviewRangeThumbLabel {
  position: absolute;
  top: 15px;
  left: 0;

  white-space: nowrap;
}

.firstThumb .chartPreviewRangeThumbLabel {
  position: absolute;
  top: 15px;
  right: 0;

  white-space: nowrap;
}

/*  Time switcher  */
.timeSwitcherGroup {
  display: flex;

  border: 1px solid var(--palette-primary-disabled);
  border-radius: 4px;
}

.timeSwitcher {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 58px;
  height: 36px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: background-color 0.2s;

  border: none;
  outline: none;
  background-color: transparent;
  appearance: none;
}

.timeSwitcher::-moz-focus-inner {
  border: 0;
}

.timeSwitcher.active {
  background-color: var(--palette-primary-transparent);
}

.timeSwitcher:first-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: 1px;

  content: "";

  background-color: var(--palette-primary-disabled);
}

/* Chart freeze */
.frozenArea path,
.frozenBar path {
  fill: var(--palette-primary);
  stroke: transparent;
  fill-opacity: 1;
  stroke-width: 0;
}

.barHidden {
  display: none;
}

.responsiveAreaChartContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 992px) {
  .chartWrap {
    --chart-shift-mobile: 16px;
    width: calc(100% + var(--chart-shift-mobile));
    margin-left: calc(var(--chart-shift-mobile) * -1);
  }
}

@media (max-width: 992px) {
  .jobChartSelectsWrap {
    margin: 0 0 20px;
  }
}
@media (max-width: 768px) {
  .jobChartWrap {
    padding: 10px;
  }

  .jobChartSelectsWrap {
    justify-content: space-between;

    margin: 0 0 20px;
  }

  .jobChartSelect {
    width: 49%;
    min-width: initial;
  }

  .chartPreviewWrap {
    margin: 20px 10px 40px 10px;
  }

  .chartPreviewRangeThumb:not(.firstThumb) .chartPreviewRangeThumbLabel {
    left: -25px;
  }

  .firstThumb .chartPreviewRangeThumbLabel {
    right: -25px;
  }

  .timeSwitcherGroup {
    width: 49%;
  }

  .timeSwitcher {
    width: 50%;
  }
}
