.container {
  background-color: #f4f4f5;
  border-radius: 6px;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
}

.button {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.75rem;
  color: #71717a;
  background-color: #f4f4f5;
  text-decoration: none;
  transition: 0.2s ease;
  cursor: pointer;
  width: 100%;
  min-width: 100px;
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  border: 1px solid #dadada;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-right: 8px;
}

.icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: left;
}

.active {
  color: #09090b;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.invalid {
  border: 1px solid #ecc4bc;
}

.iconSuccess {
  color: var(--palette-success);
}

.iconError {
  color: var(--palette-error);
}
