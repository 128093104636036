.dateCell {
  display: flex;
  column-gap: 10px;

  align-items: center;

  white-space: nowrap;
}

.name {
  max-width: 40%;
}
