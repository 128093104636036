.form {
  width: 100%;
  padding: 32px 16px;

  border-radius: 5px 10px 10px 5px;
  background: var(--palette-default);
}

.formContent {
  display: flex;
  flex-direction: column;
}

.formButtons {
  margin-top: 15px;
}

.permissionsMessage {
  margin-top: 15px;
}

/* Collapsible head */

.headSection {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  margin: 10px 0 0;
  padding: 10px;

  cursor: pointer;
  text-align: left;

  border: 0;
  border-radius: 10px;
  background: transparent;
  column-gap: 20px;
}

.entityName {
  flex: 1 1 auto;

  cursor: pointer;

  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}

.collapseIcon {
  transform: rotate(180deg);
}

.collapseIcon.isCollapsed {
  transform: rotate(0);
}

/* Roles table form */

.responsiveTable {
  overflow-x: auto;

  padding: 0 15px;
}

.roleTable {
  width: 100%;
}

.roleTable thead td {
  white-space: nowrap;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
  font-style: normal;
}

.roleTable td {
  padding: 11px 10px;
}

.roleTable td:first-child {
  padding-left: 0;
}

.roleTable td:last-child {
  padding-right: 0;
}

.roleTable tbody td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

/* Table sizes */

.roleTable td:first-child {
  width: 200px;
}

/* Actions */

.trigger {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.delete {
  color: var(--palette-error) !important;
}

/* Media */

@media (max-width: 768px) {
  .entityName {
    font-size: 15px;
  }
}

@media (max-width: 560px) {
  .entityName {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .headSection :global .checkbox-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
}
