.componentWrapper {
  display: flex;
  gap: 2px;
}

.errorTooltipTrigger {
  display: flex;
  align-items: center;

  width: 18px;
}

.errorTooltipContent {
  width: 197px;
  padding: 8px;

  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
}
