.badge {
  font-size: 12px;
  line-height: 16px;
  background-color: #f4f4f5ff;
  color: var(--palette-text-primary);
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  border-radius: 8px;
}

.pending {
  box-shadow: var(--palette-box-shadow);
  color: var(--palette-text-primary) !important;

  svg {
    color: var(--palette-text-primary) !important;
  }

  span {
    color: var(--palette-text-primary) !important;
  }
}

.success {
  box-shadow: var(--palette-box-shadow);
  color: var(--palette-success) !important;

  svg {
    color: var(--palette-success) !important;
  }

  span {
    color: var(--palette-success) !important;
  }
}

.warning {
  box-shadow: var(--palette-box-shadow);
  color: var(--palette-warning) !important;

  svg {
    color: var(--palette-warning) !important;
  }

  span {
    color: var(--palette-warning) !important;
  }
}

.finished {
  box-shadow: var(--palette-box-shadow);
  color: #228b22 !important;

  svg {
    color: #228b22 !important;
  }

  span {
    color: #228b22 !important;
  }
}

.failed {
  box-shadow: var(--palette-box-shadow);
  color: var(--palette-error) !important;

  svg {
    color: var(--palette-error) !important;
  }

  span {
    color: var(--palette-error) !important;
  }
}

.info {
  box-shadow: var(--palette-box-shadow);
  color: var(--palette-info) !important;

  svg {
    color: var(--palette-info) !important;
  }

  span {
    color: var(--palette-info) !important;
  }
}
