.allocationUnitWrap {
  display: flex;
  align-items: center;

  padding: 10px 12px;
}

.unitName {
  min-width: 136px;
  max-width: 136px;
}

.rangeWrap {
  width: 100%;
  margin: 0 24px;
}

.distributionActionsWrap {
  display: flex;
  align-items: center;
}

.distributionActionValueWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  border-bottom: 1px solid var(--palette-primary-disabled);
}

.range.disabled [class*="progress"] {
  background-color: var(--palette-primary-disabled);
}

.distributionValue {
  min-width: 70px;
  max-width: 70px;
  margin-right: 12px;

  color: var(--grey-body);

  border: 0;
  background: transparent;
}

.mathActions {
  display: flex;
  align-items: center;
}

.mathActions > button {
  height: initial;
  padding: 0;
}

.mathActions > button:first-of-type {
  margin-right: 6px;
}

.distributionActionsWrap .distributionFillButton {
  height: unset;
  margin-left: 6px;
  padding: 0 6px;
}
