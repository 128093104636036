.activityLogWrap {
  position: relative;

  display: flex;
  flex-direction: column;

  margin-top: 20px;
  padding: 12px 24px 4px;

  border-radius: 20px;
  background: var(--palette-default);
}

.activityLogText {
  position: relative;
  z-index: 1;

  display: block;

  margin-bottom: 8px;

  color: var(--palette-text-secondary);

  font-weight: 600;
}

.logsInfoPagination {
  margin-top: 16px;
}

.logItemsArrowIcon {
  position: absolute;
  top: 16px;
  right: 28px;

  margin-left: auto;

  cursor: pointer;

  transform: rotate(270deg);
}

:global(.collapseSelf__observer--closed) .logItemsArrowIcon {
  transition: transform ease-in 0.2s;
  transform: rotate(90deg);
}

.logItemsArrowIcon * {
  fill: var(--palette-primary-disabled);
}

.logItemStatusesWrap {
  display: flex;
  align-items: center;
}

.clockTimeTextStyles {
  min-width: 40px;
}

.arrowIconStyles {
  transform: rotate(90deg);
}

.logItemWrap {
  display: flex;
  gap: 8px;
}

.logItemTimeWrap {
  display: flex;

  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  gap: 4px;
}

@media screen and (min-width: 1440px) {
  .logItemTimeWrap {
    display: flex;
    flex-direction: row;

    gap: 4px;
  }
}

.timeWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}

.logItemTime {
  margin-right: 4px;
}

.logItemStatusesWrap {
  display: flex;
  gap: 4px;

  margin-left: 2px;
}

.logItemsWrap {
  display: flex;
  flex-direction: column;

  gap: 4px;
}

.paginationWrap {
  padding-bottom: 16px;
}

.logItemStatusesReconfig {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
