.container {
  border-radius: 16px;
}

.title {
  margin-bottom: 12px;

  color: var(--palette-text-default);

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.subtitle {
  margin-bottom: 34px;

  color: var(--palette-primary-disabled);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
}

.container .form {
  max-width: 600px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  gap: 10px;

  margin-top: 10px;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.buttons button:last-child {
  margin-left: 15px;
}
