.container {
  position: relative;
  z-index: 2;

  min-height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 5px;
}

@media (max-width: 895px) {
  .content {
    grid-template-columns: 100%;
    gap: 5px;
  }
}

.status {
  height: 100%;
  padding: 40px;

  border-radius: 10px;
  background: var(--palette-default);
}
