.container {
  display: flex;
  flex-direction: row;

  width: 100%;
  max-width: 100%;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100vh;

  transition: all 400ms ease;
}

@media (max-width: 992px) {
  .container {
    overflow-x: hidden;
  }

  .content {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw !important;
  }
}

.topBar {
  width: 100%;
  background-color: var(--palette-primary);
  color: #fff;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  max-height: 150px;
  transition: max-height 400ms ease;
}

@media screen and (min-width: 768px) {
  max-height: 100px;
}

.topBarHidden {
  max-height: 0;

  button {
    transition: all 0.2s ease;
    opacity: 0 !important;
  }

  div {
    transition: all 0.2s ease;
    opacity: 0 !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .topBar {
    padding: 0 48px;
  }
}
