.wrapper {
  position: relative;

  display: inline-block;
  flex-direction: column;

  height: 128px;

  padding: 16px;

  border: 1px solid var(--palette-grey-table-bg);
  border-radius: 8px;
}

.tooltipTrigger {
  position: absolute;
  z-index: 2;
  top: 18px;
  right: 18px;
}

.tooltipContent {
  width: 223px;
  padding: 8px;

  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
}

.graphTitle {
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--grey-body);

  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.contentWrapper.legendPositionTop {
  flex-direction: column-reverse;
}

.contentWrapper.legendPositionBottom {
  flex-direction: column;
}

.contentWrapper.legendPositionLeft {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.graphWrapper {
  position: relative;
}

.graphContainer {
  transform: rotate(-90deg);
}

.centralValueWrapper {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);

  color: var(--palette-text-default);

  font-size: 13px;
  line-height: 16px;
}

.centralValue {
  color: var(--grey-body);

  font-weight: 500;
}

.centralValueText {
  color: var(--palette-text-secondary);

  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
}

.legendWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;

  margin: 0;
  padding: 0;
}

.legendItem {
  display: flex;
  flex-direction: column;
}

.legendTitle {
  display: flex;

  text-transform: capitalize;

  color: var(--palette-text-default);

  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  gap: 8px;
}

.legendValue {
  color: var(--palette-text-secondary);

  font-size: 8px;
  font-weight: 500;
  line-height: 15px;
}

.colorIndicator {
  position: relative;
  top: 7px;

  display: inline-block;

  width: 8px;
  height: 8px;

  border-radius: 100px;
}
