.logsInfoPanelWrap {
  min-height: 140px;
  padding: 12px 24px 20px;

  border-radius: 20px;
  background: var(--palette-default);
}

.totalFoundText {
  display: flex;

  justify-content: flex-end;

  margin-bottom: 10px;

  text-align: right;

  color: var(--grey-body);

  font-size: 12px;
}

.icon {
  margin-right: 6px;
}

/*  ActionsWrap   */
.logsActionsWrap {
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
}

.logsActionsWrap > *:not(:last-child) {
  margin-bottom: 12px;
}

.logsActionsWrap > button {
  width: 100%;
  padding: 0 12px;
}

/*  LogItems   */
.logItemsWrap {
  position: relative;

  min-height: 50px;
  margin-bottom: 24px;
}

.logItemWrap {
  padding: 5px 0;

  border-bottom: 1px solid var(--palette-tertiary-transparent);
  background-color: transparent;
  transition: background-color 3s ease;
}

.logItemNewlyAdded {
  background-color: var(--palette-default-active);
  transition: all 3s ease;
}

.logItemInfoWrap {
  position: relative;

  display: flex;
  align-items: flex-end;

  width: 100%;
  padding: 3px 10px;

  cursor: pointer;
  text-align: left;

  border: 0;
  background: transparent;
}

.logItemInfoWrap:hover,
.logItemInfoWrap:focus {
  background: var(--palette-default-hover);
}

.title {
  margin-bottom: 20px;

  font-size: 18px;
  font-weight: 500;
}

.logItemStatusLine {
  position: absolute;
  top: 0;
  left: -2px;

  width: 2px;
  height: 100%;

  border-radius: 2px;
  background-color: var(--palette-warning-hover);
}

.logItemStatusLine.success {
  background-color: var(--palette-success-hover);
}

.logItemStatusLine.failed {
  background-color: var(--palette-error-hover);
}

.logItemStatusLine.pending {
  background-color: var(--palette-primary-transparent);
}

.logItemTextsWrap {
  width: 100%;
  margin-right: 10px;
}

.logItemStatusText {
  font-size: 12px;
}

.logItemStatusText.failed {
  color: var(--palette-error-hover);
}

.logItemMailText {
  display: block;
  overflow: hidden;

  margin-bottom: 2px;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--grey-body);
}

.logItemDateText {
  color: var(--palette-grey-main);

  font-size: 12px;
}

/*  Pagination  */
.paginationWrap {
  display: flex;
  justify-content: flex-end;
}

/*  LogResponseModal  */
.logResponseModalWrap {
  position: relative;

  min-height: 100px;
}

.paginationWrap .logsInfoPagination .perPageSelect {
  margin-left: 0;
}

.logResponseWrap {
  margin-bottom: 50px;
}

.logResponseActionsWrap {
}

.paginationWrap .logsInfoPagination {
  justify-content: space-between;
}

@media (max-width: 600px) {
  .totalFoundText {
    text-align: left;

    color: var(--palette-text-secondary);
  }
}
