.header {
  border-bottom: 1px solid #fafafa;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 24px;

  margin: -24px -16px 12px;

  h2 {
    margin: 0;
  }

  p {
    margin: 0;
    color: var(--palette-text-secondary);
  }
}

.modal {
  padding: 24px 16px;
}

.loader {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
}

.description {
  color: var(--palette-text-secondary);
}

.stepsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
}

.steps {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;

  h4 {
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  p {
    color: var(--palette-text-secondary);
    margin-top: 0;

    strong {
      font-weight: 600;
    }
  }
}

.stepNumber {
  font-size: 16px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background-color: #fafafa;
  color: #9a9a9a;
  flex-shrink: 0;
  display: grid;
  place-items: center;
}

.buttons {
  border-top: 1px solid #fafafa;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  padding: 12px;

  margin-bottom: -24px;
  margin-left: -16px;
  margin-right: -16px;
}

.copyField {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  padding: 6px 12px;
  position: relative;
  border-radius: 10px;
}

.copyButton {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.successWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
}

.successIcon {
  color: var(--palette-success);
  width: 24px;
  height: 24px;
}

.successText {
  font-size: 18px;
  color: var(--palette-success);
  margin: 0;
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
