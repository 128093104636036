/* Date */
.dateCell {
  grid-column-gap: 8px;

  column-gap: 8px;

  align-items: center;

  white-space: nowrap;
}

.dateCell img {
  margin: 0 0 0 6px;
}
