.status {
  position: relative;

  padding-left: 16px;
}

.status::before {
  position: absolute;
  top: 50%;
  left: 0;

  display: block;

  width: 8px;
  height: 8px;

  content: "";
  transform: translateY(-50%);

  border-radius: 50%;
}

.used::before {
  background-color: var(--palette-success);
}

.unused::before {
  background-color: var(--palette-error);
}
