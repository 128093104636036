.allocationWrapper.treeFileTab {
  margin-bottom: 16px;

  background: none;
}

.allocationWrapper .treeFileTab__contentWrapper {
  padding: 0;
}

.allocationWrapper .treeFileTab__navWrapper {
  margin-right: 12px;
  padding: 24px 16px 16px 24px;

  gap: 16px;

  border-right: none;
}

.allocationWrapper .treeFileTab__navWrapper--left {
  border: none;
}

.allocationWrapper .treeFileTab__navTitle {
  padding: 0;

  letter-spacing: 0.05em;

  color: var(--grey-body);

  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.allocationWrapper .treeFileTab__navWrapper {
  border-radius: 8px;
  background: var(--palette-default);
}

.allocationWrapper .treeFileTab__treeWrapper {
  gap: 4px;

  height: auto;
}

.allocationWrapper .treeFileItem__wrapper {
  display: flex;

  margin-right: 0;
}

.allocationWrapper .treeFileItem__name {
  display: flex;
  align-items: center;
  flex-grow: 1;

  padding: 9px 11px 9px 8px;

  color: var(--grey-body);
}

.allocationWrapper .treeFileItem__text {
  display: inline-block;
  overflow: hidden;

  max-width: 116px;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.allocationWrapper .treeFileItem__name--selected {
  border-radius: 8px;
  background-color: var(--palette-grey-lightly);
}

.allocationWrapper .treeFileItem__description {
  color: var(--palette-primary-disabled);
}
