.switcherWrapper {
  display: flex;
  gap: 2px;

  margin: 0 22px 0 18px;
}

.switcherWrapper .switcherButton {
  height: auto;
  padding: 5px;
}

.switcherButton .icon path {
  fill: var(--palette-primary-disabled);
}

.switcherButton .active path {
  fill: var(--button-svg-disabled-color);
}
