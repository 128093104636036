.jobDetailsPage .container {
  padding-top: 0;
}

.containerWrap {
  display: flex;
}

.headerContainer.override {
  padding-top: 0;
  padding-bottom: 29px;
}

.backToJobListBtn {
  padding: 0 !important;

  color: var(--palette-grey-active);
}

.backToJobListBtnIcon {
  transform: rotate(180deg);
}

.leftPanelWrap > div:last-child,
.rightPanelWrap > div:last-child {
  margin-top: 10px;
}

.leftPanelWrap {
  min-width: 66%;
  margin-right: 13px;
}

.rightPanelWrap {
  min-width: 34%;
}

@media (max-width: 992px) {
  .headerContainer.override {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (max-width: 945px) {
  .containerWrap {
    flex-direction: column;
  }

  .leftPanelWrap {
    margin: 0 0 12px;
  }

  .leftPanelWrap > div:last-child {
    margin: 12px 0 0;
  }

  .rightPanelWrap > div:last-child {
    margin: 25px 0 0;
  }
}
