.loginSlide .slideName {
  margin-bottom: 24px;

  color: var(--palette-default);

  font-size: 26px;
}

.loginSlide .slideDescription {
  margin-bottom: 36px;

  opacity: 0.85;
  color: var(--palette-default);

  font-size: 14px;
}
