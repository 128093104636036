.absolute {
  position: absolute;

  z-index: 999;
  top: 50%;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translate(-50%, -50%);
}

.normal {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 40px 10px;
}
