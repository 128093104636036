.search {
  margin-bottom: 0;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-bottom: 24px;
}

.controlsButtonsContainer {
  display: flex;
  justify-content: flex-end;

  margin-left: 40px;
  column-gap: 10px;
}

.totalSelectedChip {
  min-width: 24px;
  padding: 2px 8px;

  text-align: center;

  color: var(--button-primary-color) !important;
  border-radius: 7px;
  background-color: var(--button-primary-active-background);

  font-size: 13px;
  line-height: 16px;
}

.controlsActionButtons {
  display: flex;

  column-gap: 10px;
}

.selectButton {
  transition: all 0.2s ease;
}

.hideButton:disabled span {
  background-color: var(--button-secondary-disabled-color);
}

.deleteButton {
  color: var(--palette-error) !important;
  border-color: var(--palette-error) !important;
}

.deleteButton:focus,
.deleteButton:hover {
  border-color: var(--palette-error-focus) !important;
}

.deleteButton:disabled {
  color: rgba(224, 72, 45, 0.5) !important;

  border-color: rgba(224, 72, 45, 0.5) !important;
}

.modal {
  overflow: auto;

  width: 1000px !important;
  padding: 0 !important;
}
