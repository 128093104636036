.row:hover {
  cursor: pointer;
}

@keyframes allocatedAnimation {
  0% {
    background-color: var(--table-cell-background-color);
  }

  50% {
    background-color: var(--table-cell-background-color-hover);
  }

  100% {
    background-color: var(--table-cell-background-color);
  }
}

.row.allocatedAnimation [class*="table-cell"] {
  animation-name: allocatedAnimation;
  animation-duration: 2000ms;
}

.id {
  color: var(--palette-text-secondary);
}

.name {
  text-decoration: underline;

  color: var(--palette-info);
}

.dateCell {
  display: flex;
  column-gap: 10px;

  align-items: center;

  white-space: nowrap;
}

.eta {
  min-width: 120px;
}

.info {
  max-width: 240px;

  word-break: break-all;
  overflow-wrap: break-word;
}

.checkboxCell {
  min-width: 60px !important;
  max-width: 60px;
  padding: 0 !important;

  border-right: 1px solid var(--palette-grey-stoke);
}

.checkbox {
  width: 100%;
  height: 100%;
}

.checkbox div:first-child {
  justify-content: center;

  width: 100%;
  height: 100%;
}
