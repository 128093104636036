.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.dropZone {
  transition: height 200ms ease-in-out;
}

.dropZone.override {
  max-width: 100%;
}

.dropZone.isDragging {
  height: 300px;
}

.filesContent {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.itemSuppressionWrap {
  display: flex;
  align-items: baseline;
  column-gap: 4px;
}

.itemSuppressionWrap svg {
  min-width: 12px;
  min-height: 12px;
}

.actionsWrap {
  display: flex;
  justify-content: center;

  margin-top: 20px;
  column-gap: 10px;
}

/* Form Item Modal */
.itemModal {
  position: relative;
}

.itemModalWrapTitle {
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-weight: 600;
}

.itemModalHeader {
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  color: var(--grey-body);

  font-size: 22px;
  font-weight: 500;
}

.itemModalHeader span {
  width: 100%;
  max-width: 300px;
}

.itemModalContentWrap {
  margin-bottom: 24px;
}

.itemModalTableHeaderWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
}

.itemModalContentGridContainer {
  position: relative;

  min-height: 288px;
  max-height: 288px;
}

.itemModalContentGridContainer.hasItems {
  display: flex;

  padding: 6px 6px 0;

  border-radius: 10px 10px 3px 3px;
  background: var(--palette-tertiary-transparent);
  box-shadow: var(--palette-shadow-tiny);
}

.itemModalContentGrid {
  display: grid;
  overflow-x: auto;

  min-width: 100%;
  margin-bottom: 1px;
  grid-auto-rows: 36px;
  row-gap: 3px;
}

.gridScrollBGRow {
  position: absolute;
  bottom: 0;
  left: 0;

  min-width: 100%;
  height: 6px;

  pointer-events: none;

  border-radius: 0 0 3px 3px;
  background: var(--palette-tertiary-transparent);
}

.itemModalGridRow {
  display: flex;

  border-radius: 10px 10px 6px 6px;
  background-color: var(--palette-default);
}

.itemModalGridSelectsRow {
  background-color: var(--palette-default);
}

.itemModalGridRow.headerRow > .itemModalGridCell span {
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.itemModalGridCell {
  display: flex;
  align-items: center;

  min-width: 160px;
  max-width: 160px;
  height: 36px;
  padding: 0 12px;
}

.itemModalGridSelectCell {
  padding: 3px 12px;
}

.itemModalTableHeaderHint {
  margin-right: 30px;
}

.itemModalTableHasHeaderWrap {
  display: flex;
  align-items: center;
}

.itemModalTableHasHeaderWrap > div {
  margin: 0 8px;
}

.itemModalTableHasHeaderWrap span {
  width: 24px;

  text-align: center;
}

.itemModalSuppressionWrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  margin-bottom: 36px;
}

.itemModalMessageFieldWrap {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 15px;
}

.itemModalActionsWrap {
  display: flex;
  justify-content: flex-end;
}

.itemModalActionsWrap button:first-of-type {
  margin-right: 32px;
}

.listTypes {
  /*margin-bottom: 35px;*/
}

.listTypes label {
  margin-bottom: 8px;

  text-transform: uppercase;

  font-size: 13px;
  font-weight: 600;
}

.listsInfo {
  margin-top: 6px;

  color: var(--palette-text-secondary);

  font-size: 11px;
}
