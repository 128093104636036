.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.button {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  color: #85858a;
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;

  &:focus {
    border-bottom: 1px solid #e1e1e1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #eaeaea;
  }
}

.active {
  color: #2d2d2d;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transition: width 0.2s ease;
    background-color: #2d2d2d;
  }
}
