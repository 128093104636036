.head {
  display: flex;
  gap: 20px;

  justify-content: space-between;
}

.search {
  margin-bottom: 24px;
}
