.formWrapper {
  padding-left: 20px;

  border-left: 1px solid var(--palette-body);
}

.form {
  display: grid;
  align-self: flex-start;

  justify-content: flex-start;

  grid-template-columns: repeat(2, auto);
  gap: 20px 64px;
}

.formFieldWrapper {
  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: normal;
  line-height: 1.3;
  gap: 24px;
}

.label {
  display: flex;
  align-items: center;

  width: max-content;

  color: var(--palette-grey-active);
  gap: 4px;
}

.hoursInput {
  width: 96px;
  height: 25px;
  margin-left: auto;
  padding-left: 7px;

  color: var(--input-color);

  border: 1px solid var(--input-border);
  border-radius: 2px;
}

.hoursInput.hoursInputError {
  border: 1px solid var(--palette-error);
}

.hoursInput.disabled {
  color: var(--palette-grey-main);
}

.hoursAllocationCol {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px 64px;

  align-items: self-start;
}

.name {
  min-width: max-content;
}
