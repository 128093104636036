.month {
  position: relative;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  max-width: 118px;

  height: 12vw;
  max-height: 58px;

  margin-left: 0;
  padding: 6px 12px;

  list-style: none;

  border-right: var(--borderStyle);
  border-bottom: var(--borderStyle);
  background-color: var(--palette-shadow-tiny);
}

.monthActive {
  background-color: var(--palette-default);
}

.monthFocused {
  box-shadow: inset 0 0 0 1px var(--palette-primary);
  transition: box-shadow 0.3s ease-in-out;
}

.tooltipContent {
  border: 1px solid #f1f1f1;
  padding: 0 !important;
}

.pasteButton {
  height: auto !important;
  padding: 12px !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--palette-default-hover);
  }
}

.pasteButton div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 500;
}

.pasteButton span {
  margin-top: 2px;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.clipboardPasteIcon {
  width: 12px;
  height: 12px;
  margin-left: -4px;
}

.monthContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.monthName,
.day {
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--palette-grey-hover);

  font-size: 10px;
  font-weight: normal;
  line-height: 1.5;
}

.dayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;
  width: 100%;
  height: 15px;
}

.monthName {
  left: 12px;
}

.day {
  right: 12px;

  margin-left: auto;
}

.monthInput {
  width: 100%;
  height: 31px;
  margin-left: auto;
  padding-top: 7px;

  text-align: right;

  color: var(--palette-primary);
  border: none;
  border-bottom: 1px solid var(--palette-grey-disabled);

  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}

.monthInput:focus {
  border-bottom: 1px solid var(--palette-primary-focus);
}

.monthInput.error {
  border-bottom: 1px solid var(--palette-error);
}

.monthInput.disabled {
  color: var(--palette-grey-main);
}
