/*  Endpoint  */

.title {
  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.endpointWrap {
  display: flex;

  border-radius: 0 0 0 16px;
  background-color: var(--palette-default);
}

.leftPanelWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 41%;
}

.leftPanelContentWrap {
  margin-bottom: 36px;
  padding: 36px 24px 0;
}

.leftPanelContentWrapJob {
  margin-bottom: 0;
  padding: 24px 24px 32px;
}

.leftPanelContentWrap > *:not(:last-child) {
  margin-bottom: 12px;
}

.rightPanelWrap .submitButton {
  width: 100%;
  height: 40px;

  border-radius: 8px;
}

.rightPanelWrap {
  width: 59%;
  padding: 24px 24px 32px;

  border-left: 1px solid var(--palette-tertiary-transparent);
}

.rightPanelContentPart {
  width: 100%;
  max-width: 500px;
}

.rightPanelContentPartJob {
  max-width: 100%;
}

.rightPanelContentPart > *:not(:last-child) {
  margin-bottom: 12px;
}

.rightPanelContentPart .btnDataExtensions {
  margin-top: 9px;
  padding: 0;
  width: 40px;
  height: 40px;
  /*margin-bottom: 36px;*/
}

.tabs :global(.tabItem--selected.tabItem--disabled .tabItem__name) {
  color: var(--palette-error);
}

.proxyHint {
  width: 100%;
  margin-bottom: 16px !important;
}

.proxyText {
  font-size: 12px;
  white-space: break-spaces;
}

.proxyText span {
  font-weight: 700;
  word-break: break-word;
}

.listWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 8px;
  align-items: flex-start;
}

.aweberListWrapper {
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 8px;
  align-items: flex-start;
}

.deleteButton {
  color: var(--palette-error);
  background-color: var(--palette-error-transparent);
  width: 30px;
  height: 30px;
  display: grid;
  place-content: center;
  border: 1px solid var(--palette-error-hover);
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.caption {
  color: var(--palette-text-secondary);
  font-size: 13px;
  margin: 8px 0;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
}
