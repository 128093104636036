.unitWrapper > *:first-child {
  min-width: 40px;
  max-width: 40px;
}

.hintWrapper {
  display: flex;
  justify-content: flex-end;

  margin-top: 16px;
}

.statisticBar {
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  margin-bottom: 16px;
}

.daysTabWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 27px;
}

.componentWrapper {
  padding: 24px;

  border-radius: 8px;
  background: var(--palette-default);
}

.hintWrapper {
  display: flex;
  justify-content: flex-end;
}

.hintWrapper > div {
  margin-top: -8px;
}
