.fileNameCell {
  min-width: 248px;
  max-width: 248px;
}

.statusIndicator {
  width: 6px;
  height: 6px;

  content: "";

  border-radius: 100%;
}

.dateCell {
  display: flex;
  column-gap: 10px;

  align-items: center;

  white-space: nowrap;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
}

.job {
  flex: 1 1 auto;

  max-width: calc(100% - 70px);
}

.jobList {
  padding: 8px 8px 4px;
}
