.title {
  display: flex;
  justify-content: space-between;

  margin: 0;

  color: var(--grey-body);

  font-size: 22px;
  font-weight: 500;
}

.tableWrap {
  margin-bottom: 10px;
  padding: 6px;

  border-radius: 8px;
  background: var(--palette-tertiary-transparent);
}

.table {
  max-height: 400px;
}
