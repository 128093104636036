.presetsWrapper {
  margin-bottom: 12px;
  padding: 9px 24px;

  transition: padding 0.2s;

  border-radius: 8px;
  background-color: var(--palette-default);
}

.presetsWrapper.isOpened {
  padding: 24px;
}

.presetsWrapper .presetTrigger {
  display: flex;
}

.presetsHeadlineBox {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.presetsHeadline {
  margin-top: 0;
  margin-bottom: 0;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--grey-body);

  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.collapseIcon path {
  fill: var(--palette-primary-disabled);
}

.collapseIcon {
  transition: transform 0.2s;
}

.collapseIcon.isOpened {
  transform: rotate(180deg);
}

.controlsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 8px;
}

.presetsWrapper .applyCheckbox label {
  padding-left: 10px;

  color: var(--palette-text-default);

  font-weight: 400;
}

.presetsField {
  flex-grow: 1;
}

.presetsField:hover .presetsInput:not(:focus) {
  border-color: var(--palette-tertiary-hover);
}

.presetsField:hover .presetsInput ~ button path {
  fill: var(--palette-tertiary-hover);
}

.presetsField label span {
  letter-spacing: 1px;

  color: var(--palette-primary);

  font-size: 10px;
  line-height: 1.5;
}

.presetsButton {
  margin-top: 9px;
}
