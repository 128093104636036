.error {
  fill: var(--palette-error-transparent);
}

.infoPrimary {
  fill: var(--palette-primary-transparent);
}

.infoTextTertiary {
  fill: var(--palette-default);
}

.infoTertiary {
  fill: var(--palette-primary-disabled);
}
