.modal {
  overflow: auto;
  padding: 0 !important;
}

.content {
  padding: 24px;

  p {
    font-size: 14px;
  }
}

.modalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  border-top: 1px solid #eaeaea;
  padding: 12px 24px;
}
