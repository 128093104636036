/* File Name */
.fileNameCell {
  display: flex;
  flex-direction: column;
}

.fileNameCell .fileNameCellText {
  max-width: 248px;
}

.suppressionFilesCell {
  width: 178px;
}

.headersCell {
  max-width: 250px;
}

.additionalFile {
  display: flex;
  align-items: center;
  column-gap: 10px;

  color: var(--palette-text-secondary);
}

/* Date */
.dateCell {
  column-gap: 10px;
}

/* Stage */
.stage {
  column-gap: 10px;
}
