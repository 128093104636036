.name {
  text-decoration: underline;
  font-weight: 600;
}

.dateCell {
  display: flex;
  column-gap: 10px;

  align-items: center;

  white-space: nowrap;
}
