.filterWrap {
  display: flex;
  align-items: center;

  margin-bottom: 24px;
}

.filterWrap button {
  margin-left: 16px;
  padding: 0 18px;
}

div.tabWrapAllocation {
  max-width: max-content;
}
