.weekList {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.weekList {
  display: flex;
  flex-direction: column;

  width: 176px;

  color: var(--grey-body);

  font-size: 13px;
}
