.unitWrapper > *:first-child {
  min-width: 50px;
  max-width: 50px;
}

.statisticBar {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.calendarWrapper {
  margin-top: 24px;
}

.hintWrapper {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
}

.componentWrapper {
  padding: 24px;

  border-radius: 8px;
  background: var(--palette-default);
}
