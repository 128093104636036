.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.tabsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 4px;
  column-gap: 2px;
  margin-top: 6px;

  background-color: #f4f4f5;
  border-radius: 6px;
  padding: 0.25rem;
}

.content {
  margin-top: 12px;
  margin-bottom: 24px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 6px;

  @media screen and (min-width: 662px) {
    display: grid;
    gap: 0;
    grid-template-columns: 40% 60%;
    justify-content: space-between;
  }
}

.inputLabel {
  margin-top: 4px;
  padding-right: 8px;
}

.dataExtensionContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.description {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.mainLabel {
  font-size: 13px;
  font-weight: 700;
  color: var(--label-color);
  margin-bottom: 0;
}
