.wrapper {
  width: 100%;
  max-width: 619px;
  height: 128px;
  padding: 16px;

  border: 1px solid var(--palette-grey-table-bg);
  border-radius: 8px;
}

.chartHeader {
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--grey-body);

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.graphWrapper {
  position: relative;

  width: 100%;
  height: 70px;
  margin: 8px auto 0 auto;
}

.graphWrapper :global(.recharts-xAxis .recharts-cartesian-axis-tick-value) {
  fill: var(--palette-grey-active);

  font-weight: 500;
}

.graphWrapper :global(.recharts-rectangle) {
  fill: var(--table-row-selected);
}

.graphWrapper :global(.recharts-rectangle:hover) {
  fill: var(--palette-primary-hover);
}
