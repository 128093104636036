.container {
  position: relative;

  flex: 1 1 auto;

  width: 100%;
  max-width: 100vw;
  padding: 24px 48px;
}

.content {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding: 20px 24px;
  }
}
